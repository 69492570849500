import React from "react"
import yamlData from "../content/estimate.yaml"
import Container from "react-bootstrap/Container"
import { Col, Row } from "react-bootstrap"
import {
  boxEstimate,
  containerEstimate,
  paragrapheEstimate,
  picture,
  titleEstimate,
  subTitleEstimate,
} from "../styles/estimate.module.css"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Estimate = () => {
  const images = useStaticQuery(graphql`
    query {
      redCar: file(relativePath: { eq: "estimation-prix-d-une-épave.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      multiCar: file(relativePath: { eq: "loi-francaise.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <div className={boxEstimate}>
      <Container className={containerEstimate}>
        <h2 className={titleEstimate}>{yamlData.title}</h2>
        <Row>
          <Col lg={3} md={6} sm={6}>
            <figure className={picture}>
              <Img
                fluid={images.redCar.childImageSharp.fluid}
                alt="epaviste_dulin"
              />
            </figure>
          </Col>
          <Col lg={3} md={6} sm={6}>
            <h3 className={subTitleEstimate}>{yamlData.subTitle1}</h3>
            <p className={paragrapheEstimate}>{yamlData.paragraphe1}</p>
          </Col>
          <Col lg={3} md={6} sm={6}>
            <figure className={picture}>
              <Img
                fluid={images.multiCar.childImageSharp.fluid}
                alt="epaviste_dulin"
              />
            </figure>
          </Col>
          <Col lg={3} md={6} sm={6}>
            <h3 className={subTitleEstimate}>{yamlData.subTitle2}</h3>
            <p className={paragrapheEstimate}>{yamlData.paragraphe2}</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Estimate

import React from "react"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import SlideEpaviste from "../components/landing/slideEpaviste"
import Description from "../components/landing/description"
import Document from "../components/landing/document"
import Faq from "../components/landing/faq"
import Estimate from "../templates/department/components/estimate"
import Questions from "../templates/department/components/questions"

const RachatVehicule = () => (
  <Layout>
    <Seo
      title="Epaviste Dulin, centre VHU agréé pour racheter les véhicules les épaves en Ile de France"
      description="Formulaire de contact, déscription des formalités pour le rachat d'épave par nos service"
    />
    <SlideEpaviste 
      backgroundImage={"rachat"}
      para={"Pour estimer le montant du rachat de votre véhicule hors d'usage,"}
    />
    <Description
      titre={"Comment se déroule l'estimation de rachat d'une épave ?"}
      para={"Nous commençons à estimer les véhicules de moins de 15 ans, suivant leurs états (motorisation, pneu, carrosserie, ancienneté du véhicule) ainsi que le marché de la ferraille, nous faisons des propositions qui peuvent aller jusqu'à 500 €. Une fois que nous nous sommes mis d'accord sur le montant, nous venons remorquer votre véhicule pour la transporter dans notre centre de recyclage agréé. Ce centre permet de traiter le véhicule afin d’éliminer ses éléments toxiques tels que l’huile du moteur, le caoutchouc, etc. Le service est entièrement gratuit, vous devez simplement nous fournir certains documents."}
    />
    <Estimate />
    <Document 
      titre={"Les documents à fournir pour finaliser le rachat de votre véhicule"}
    />
    <Questions 
      page={"rachat"}
    />
    <Faq />
  </Layout>
)

export default RachatVehicule

